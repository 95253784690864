<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>设备详情</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="100px" size="large" style="width: 90%">
          <el-form-item label="名称">
            <span>{{equipments.name}}</span>
          </el-form-item>
<!--          <el-form-item label="设备类型">-->
<!--            <span>{{equipments.type}}</span>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="所属部门">-->
<!--            <span>{{partments.find(v => v.id === equipments.partId) ? partments.find(v => v.id === equipments.partId).name : ''}}</span>-->
<!--          </el-form-item>-->
          <el-form-item label="所属车间">
            <span>{{lines.find(v => v.id === equipments.lineId) ? lines.find(v => v.id === equipments.lineId).name : ''}}</span>
          </el-form-item>
          <el-form-item label="MTBF">
            <span>{{equipments.mtbf}}</span>
          </el-form-item>
          <el-form-item label="MTTR">
            <span>{{equipments.mttr}}</span>
          </el-form-item>
          <el-form-item label="MTTF">
            <span>{{equipments.mttf}}</span>
          </el-form-item>
          <el-form-item label="历史故障">
          </el-form-item>
        </el-form>
        <el-card v-for="item in reports" :key="item.id" style="margin-bottom: 20px;">
          <div slot="header">
            <div>{{ item.failureType }} | {{ item.type }}
              <el-button style="float: right;" @click="goToDetail(item.id)" size="small">查看</el-button>
            </div>
          </div>
          <div>
            <div><span>故障时间: {{ item.failureTime }}</span></div>
            <div><span>故障描述：{{ item.description }}</span></div>
          </div>
        </el-card>
        <el-row type="flex" justify="center">
          <el-button @click="goToHome" size="large">返回</el-button>
        </el-row>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "MEquipmentDetail",
  data() {
    return {
      users: [],
      equipments: [],
      partments: [],
      lines: [],
      reports: [],
      equipmentId: null,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      console.log('item id:', this.$route.params.id);
      this.equipmentId = this.$route.params.id;
      this.request.get("/equipment/"+this.equipmentId).then(res => {
        this.equipments = res.data;
      });
      this.request.get("/repairReport/findReport/"+this.equipmentId).then(res => {
        this.reports = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      // this.request.get("/partment").then(res => {
      //   this.partments = res.data;
      // });
      this.request.get("/line").then(res => {
        this.lines = res.data;
      });
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mequipment');
    },
    goToDetail(reportId) {
      console.log('Clicked item id:', reportId);
      this.$router.push({ name: 'MHistory', params: { id: reportId } });
    },
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
